import axios from "axios";
import { loadAbort } from "../utilities";

const route = process.env.REACT_APP_API_COMPANY_URL + "company";

// eslint-disable-next-line import/prefer-default-export
export const post = (data) => {
  const controller = loadAbort();
  return {
    call: () => axios.post(route, { ...data }, { signal: controller.signal }),
    controller,
  };
};

export const getAll = (page: number = 1, pageSize: number = 10) => {
  const controller = loadAbort();
  return {
    call: () => axios.get(route, { signal: controller.signal }),
    controller,
  };
};

export const getAllCompanies = (page: number = 1, pageSize: number = 10) => {
  const controller = loadAbort();
  return {
    call: () => axios.get(route, { signal: controller.signal }),
    controller,
  };
};

export const GetAllDashboard = (page: number = 1, pageSize: number = 10) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/GetAllDashboard?page=${page}&pageSize=${pageSize}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const getAllWithFilters = (
  page: number = 1,
  pageSize: number = 10,
  text: any
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.patch(
        `${route}/GetAllDashboardWithFilters?page=${page}&pageSize=${pageSize}&searchQuery=${text}`,
        null,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getById = (id: string) => {
  const controller = loadAbort();
  return {
    call: () => axios.get(`${route}/${id}`, { signal: controller.signal }),
    controller,
  };
};

export const remove = (id) => {
  const controller = loadAbort();
  return {
    call: () => axios.delete(`${route}/${id}`, { signal: controller.signal }),
    controller,
  };
};

export const put = (id, data) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.put(`${route}/${id}`, { ...data }, { signal: controller.signal }),
    controller,
  };
};

export default {
  post,
  remove,
  put,
};
