import { configureStore } from "@reduxjs/toolkit";
// reducers
import userReducer from "./slices/user";
import sessionsReducer from "./slices/sessions/reducers";

export default configureStore({
  reducer: {
    user: userReducer,
    sessions: sessionsReducer,
  },
});
