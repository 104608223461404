// reducers.js
const initialState = {
  finalizada: false,
  agendada: false,
  cancelada: false,
  fechaInicial: null,
  fechaFinal: null,
  // ... other filters
};

const sessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FINALIZADA":
      return {
        ...state,
        finalizada: action.payload,
      };
    case "SET_AGENDADA":
      return {
        ...state,
        agendada: action.payload,
      };
    case "SET_CANCELADA":
      return {
        ...state,
        cancelada: action.payload,
      };
    case "SET_FECHA_INICIAL":
      return {
        ...state,
        fechaInicial: action.payload,
      };
    case "SET_FECHA_FINAL":
      return {
        ...state,
        fechaFinal: action.payload,
      };
    // Add other cases for different filters if needed
    default:
      return state;
  }
};

export default sessionsReducer;
