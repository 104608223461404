import axios from "axios";
import { loadAbort } from "utilities";

export interface IMidNPS {
  coachee: object;
  recomendation: number;
  usefulAspect: string;
  obstacles: string;
}

const midNps = `${process.env.REACT_APP_API_URL}midnps`;

export const getAllDashbordMidNps = (page: number, pageSize: number) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.get(`${midNps}/getalldashboard`, {
        signal: controller.signal,
        params: {
          page,
          pageSize,
        },
      }),
    controller,
  };
};

export const getMidNpsByRangeOfDates = (start: string, end: string) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.get(`${midNps}/getByRangeOfDates`, {
        signal: controller.signal,
        params: {
          start,
          end,
        },
      }),
    controller,
  };
};
