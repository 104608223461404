import { useState, useEffect } from "react";
import { Typography, Table, Button, Card } from "antd";
import useFetchAndLoad from "hooks/useFetchAndLoad";
import { useNavigate, useParams } from "react-router-dom";
import coacheeService, { getByCohortId } from "services/coachee.service";
import NewTable from "components/Table/NewTable";
import { GetByIdWithCompany } from "services/cohort.service";

const { Text } = Typography;

const CoacheesByCohort = () => {
  const [cohortInfo, setCohortInfo] = useState(null);
  const [coachees, setCoachees] = useState();
  const [totalCoachees, setTotalCoachees] = useState(null);
  const { loading, callEndpoint } = useFetchAndLoad();
  const { id } = useParams();

  const router = useNavigate();

  const getCoacheesByProgramId = async () => {
    try {
      const { data } = await callEndpoint(getByCohortId(id));
      setCoachees(data.data);
      setTotalCoachees(data.data.length);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getCohortInfo = async () => {
    try {
      const { data } = await callEndpoint(GetByIdWithCompany(id));
      setCohortInfo(data.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getCoacheesByProgramId();
    getCohortInfo();
  }, []);

  const coacheeColumns: any[] = [
    {
      title: <span style={{ fontSize: "10px" }}>Compañía</span>,
      key: "company",
      dataIndex: "company",
      align: "center",
      sorter: (a, b) =>
        String(a?.company?.name).localeCompare(b?.company?.name),
      render: (company) => (
        <div className="flex justify-center align-center">
          {company && company.urlImgCompany ? (
            <img
              className="mr-2"
              width="30"
              height="30"
              src={company.urlImgCompany}
              alt={company.name}
            />
          ) : (
            ""
          )}
          {company ? company.name : ""}
        </div>
      ),
      width: "10%",
      editable: true,
    },
    {
      title: <span style={{ fontSize: "10px" }}>Nombre</span>,
      dataIndex: "name",
      key: "name",
      width: "10%",
      align: "center",
      sorter: (a, b) => String(a.name).localeCompare(b.name),
      // editable: true,
      // searchable: true,
    },
    {
      title: <span style={{ fontSize: "10px" }}>Apellido</span>,
      key: "lastname",
      dataIndex: "lastname",
      align: "center",
      sorter: (a, b) => String(a.lastname).localeCompare(b.lastname),
      // searchable: true,
      width: "10%",
      // editable: true
    },
    {
      title: <span style={{ fontSize: "10px" }}>Correo</span>,
      key: "email",
      dataIndex: "email",
      align: "center",
      sorter: (a, b) => String(a.email).localeCompare(b.email),
      width: "10%",
      // ellipsis: true,
      // editable: true,
      // searchable: true
    },
    {
      title: <span style={{ fontSize: "10px" }}>Coach Responsable</span>,
      key: "coach",
      dataIndex: "coach",
      width: "10%",
      align: "center",
      sorter: (a, b) =>
        String(`${a.name} ${a.lastname}`).localeCompare(
          `${b.name} ${b.lastname}`
        ),
      render: (coach) =>
        `${coach ? coach.name : ""} ${coach ? coach.lastname : ""}`,
      ellipsis: true,
      // editable: true
    },
  ];

  console.log("cohortInfo,", cohortInfo);

  return (
    <>
      <Card>
        <Button
          type="primary"
          onClick={() => {
            router(-1);
          }}
        >
          REGRESAR
        </Button>
        <div className="flex justify-center">
          <Text className="my-10 text-3xl font-normal text-center text-gray-400">
            Coachees
          </Text>
        </div>

        <div className="ml-4 flex flex-col">
          {cohortInfo?.Company?.name && (
            <span className="mb-3">
              Empresa :{" "}
              <span className="font-bold">{cohortInfo?.Company?.name}</span>
            </span>
          )}

          {cohortInfo?.Program && (
            <span className="mb-3">
              Programa:{" "}
              <span className="font-bold">{cohortInfo?.Program} Sesiones</span>
            </span>
          )}

          {totalCoachees && (
            <span>
              Numero de coachees:{" "}
              <span className="font-bold">{totalCoachees}</span>
            </span>
          )}
        </div>

        <div className="flex flex-col justify-center items-center">
          <NewTable
            loading={loading}
            originData={coachees}
            columns={coacheeColumns}
            actions={{
              view: () => "coachees/view",
              edit: () => "coachees/edit",
            }}
            addTitle={"Añadir Coachee por Formulario"}
            service={coacheeService}
          />
        </div>
      </Card>
    </>
  );
};

export default CoacheesByCohort;
