import useFetchAndLoad from "../../../../hooks/useFetchAndLoad";
import { removeSession } from "../../../../services/session.service";
import displayToast from "utilities/toast.utility";
import Loading from "../../../../common/Loading/Loading";
import { useState } from "react";

const SessionDeletePopUp = ({ setShowModal, sessionId, refresh }) => {
  const [loading, setLoading] = useState(false);

  const { callEndpoint } = useFetchAndLoad();

  const handleRemove = async () => {
    try {
      setLoading(true);

      await callEndpoint(removeSession(sessionId));

      await refresh();

      await setLoading(false);

      displayToast("Sesion eliminada con éxito", "success");

      await setShowModal(false);
    } catch (error) {
      displayToast("La sesion no se pudo eliminar", "error");

      await setLoading(false);
    }
  };

  if (loading === false) {
    return (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="mb-7 flex justify-end items-end">
                <button
                  type="button"
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  onClick={() => setShowModal(false)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-10 h-10"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/*body*/}
              <div className="p-6 text-center">
                <svg
                  aria-hidden="true"
                  className="mx-auto mb-4 text-gray-400 w-36 h-36"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <h3 className="text-3xl mb-5 font-normal text-gray-500 ">
                  Estas seguro/a de que quieres eliminar esta sesion?
                </h3>
                <div className="flex justify-center mb-8">
                  <button
                    data-modal-hide="popup-modal"
                    type="button"
                    onClick={() => handleRemove()}
                    className="text-white mt-6 text-3xl bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg inline-flex items-center px-5 py-2.5 text-center mr-2"
                  >
                    Si, estoy seguro
                  </button>
                  <button
                    data-modal-hide="popup-modal"
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="text-gray-500 mt-6 text-3xl bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 "
                  >
                    No, cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    );
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-64 py-24">
            {/*body*/}
            <div className="p-6 text-center">
              <div className="">
                <Loading />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default SessionDeletePopUp;
