import React, { useState } from "react";
import { Avatar, Divider, IconButton } from "@chakra-ui/react";
import { RiNotification3Fill } from "react-icons/ri";
import { signOut } from "firebase/auth";
import { auth } from "utilities/admin.firebase.utility.ts";
import { NotAllowedIcon } from "@chakra-ui/icons";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigation = useNavigate();

  const logout = async () => {
    try {
      localStorage.clear();
      await signOut(auth);
      await navigation.navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const [menuItems] = useState([
    {
      icon: NotAllowedIcon,
      name: "Cerrar Sesión",
      onClick: () => logout(),
    },
  ]);

  return (
    <div className="Header">
      <div className="Header__info">
        <Menu>
          <MenuButton rightIcon={<NotAllowedIcon />}>
            <Avatar />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={logout}>Log Out</MenuItem>
          </MenuList>
        </Menu>
        {/* <IconButton
          icon={<RiNotification3Fill className="Header__info_notification" />}
          aria-label="Notifications"
        />
        <Divider orientation="vertical" className="Header__info_divider" /> */}
      </div>
    </div>
  );
}

export default Header;
