import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import useFetchAndLoad from "hooks/useFetchAndLoad";
import { Card, Checkbox, Typography, Select, Radio } from "antd";
import { Input, Button, Form } from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getAllQuestions } from "services/question.service";
import { getAll } from "services/focusareas.service";
import { getByCompany } from "services/cohort.service";
import { getAll as GetAllCompanies } from "services/companies.service";
import displayToast from "utilities/toast.utility";
import {
  addProgram,
  editProgram,
  getProgramById,
} from "services/program.service";

const { Text } = Typography;

function AddProgram() {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const router = useNavigate();

  const { loading, callEndpoint } = useFetchAndLoad();
  const { id } = useParams();

  // program data

  const [programsData, setProgramsData] = useState(null);

  const getProgramData = async () => {
    try {
      const { data } = await callEndpoint(getProgramById(id));
      setProgramsData(data.data[0]);
    } catch (error) {
      console.error("Error fetching program data", error);
    }
  };

  useEffect(() => {
    if (window.location.pathname.includes("edit")) {
      getProgramData();
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const initialValues = () => ({
    name: null,
    company: null,
    cohort: null,
    status: null,
    limitCoaches: false,
  });

  useEffect(() => {
    if (window.location.pathname.includes("edit") && programsData) {
      setSelectedFocusAreas(programsData.focusAreas || []);
      setSelectedQuestions(programsData.questions || []);

      for (const key in initialValues()) {
        if (Object.prototype.hasOwnProperty.call(programsData, key)) {
          const element = programsData[key];
          form.setFieldsValue({ [key]: element });
          setFieldValue(key, element);

          // Establecer la compañía seleccionada
          const selectedCompany = programsData.company?._id;
          if (selectedCompany) {
            form.setFieldsValue({ company: selectedCompany });
            setFieldValue("company", selectedCompany);
          }

          // Establecer la cohorte seleccionada
          const selectedCohort = programsData.cohort?._id;
          if (selectedCohort) {
            form.setFieldsValue({ cohort: selectedCohort });
            setFieldValue("cohort", selectedCohort);
          }
        }
      }
    }
  }, [programsData]);

  const { handleSubmit, setFieldValue, handleChange, values } = useFormik({
    initialValues: initialValues(),
    onSubmit: async (values, { resetForm }) => {
      try {
        if (window.location.pathname.includes("add")) {
          await callEndpoint(
            addProgram({
              name: values.name,
              company: values.company,
              cohort: values.cohort,
              status: values.status,
              selectedFocusAreas,
              questions: selectedQuestions,
            })
          );
          displayToast("Programa agregado con éxito", "success");
          navigate("/programs");
        }

        if (window.location.pathname.includes("edit")) {
          await callEndpoint(
            editProgram(id, {
              name: values.name,
              company: values.company,
              limitCoaches: values.limitCoaches,
              // cohort: values.cohort,
              status: values.status,
              // focusAreas: selectedFocusAreas,
              // questions: selectedQuestions,
            })
          );
          displayToast("Programa editado con éxito", "success");
          navigate("/programs");
        }
      } catch (error: any) {
        if (error?.response?.data?.message) {
          displayToast(error.response.data.message, "error");
        }
      }
    },
  });

  useEffect(() => {}, [loading]);

  const TitleCustom = () => {
    if (window.location.pathname.includes("view")) {
      return <h2 className="title_table">Ver Programa</h2>;
    } else if (window.location.pathname.includes("edit")) {
      return <h2 className="title_table">Editar Programa</h2>;
    } else {
      return <h2 className="title_table">Agregar Programa</h2>;
    }
  };

  // FOCUS AREAS

  const [focusAreas, setFocusAreas] = useState([]);
  const [selectedFocusAreas, setSelectedFocusAreas] = useState(
    programsData?.focusAreas || []
  );
  const [selectedQuestions, setSelectedQuestions] = useState(
    programsData?.questions || []
  );

  // const handleFocusAreas = (checkedValues) => {
  //   setSelectedFocusAreas(checkedValues);
  // };

  const handleFocusAreas = (id) => {
    // Use a set to handle unique focus area ids
    const newSelectedFocusAreas = new Set(selectedFocusAreas);

    // Toggle the selection
    if (newSelectedFocusAreas.has(id)) {
      newSelectedFocusAreas.delete(id);
    } else {
      newSelectedFocusAreas.add(id);
    }

    setSelectedFocusAreas(Array.from(newSelectedFocusAreas));
  };

  const getData = async () => {
    const { data } = await callEndpoint(getAll());
    setFocusAreas(data.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const options = focusAreas?.map((focusArea) => ({
    label: focusArea.focusArea,
    value: focusArea._id,
  }));

  const [searchParams] = useSearchParams();

  const [companies, setCompanies] = useState([]);
  const companyID = searchParams.get("companyID");

  // QUESTIONS DATA

  const [questionsData, setQuestionsData] = useState(null);

  const getQuestionsData = async () => {
    const { data } = await callEndpoint(getAllQuestions());

    setQuestionsData(data?.data);
  };

  const getQuestionsByFocusArea = (focusAreaId) => {
    let questions = questionsData?.filter(
      (question) => question?.focusArea?._id === focusAreaId
    );

    return questions;
  };

  useEffect(() => {
    getQuestionsData();
  }, []);

  const handleQuestions = (id) => {
    // Use a set to handle unique question ids
    const newSelectedQuestions = new Set(selectedQuestions);

    // Toggle the selection
    if (newSelectedQuestions.has(id)) {
      newSelectedQuestions.delete(id);
    } else {
      newSelectedQuestions.add(id);
    }

    setSelectedQuestions(Array.from(newSelectedQuestions));
  };

  // company and cohorts

  const GetCompanies = async () => {
    const { data } = await callEndpoint(GetAllCompanies());
    if (Array.isArray(data.data) && data.data.length > 0) {
      setCompanies(
        data?.data?.map((x) => ({
          value: x._id,
          label: (
            <div className="flex align-center">
              {x && x.urlImgCompany ? (
                <img
                  className="mr-2"
                  width="30"
                  height="30"
                  src={x.urlImgCompany}
                  alt={x.name}
                />
              ) : (
                ""
              )}
              {x ? x.name : ""}
            </div>
          ),
        }))
      );
    }
  };

  const GetAllEntities = async () => {
    await GetCompanies();
    if (companyID) {
      form.setFieldsValue({ company: companyID });
      setFieldValue("company", companyID);
    }
  };

  useEffect(() => {
    GetAllEntities();
  }, []);

  const renderQuestionsForArea = (
    areaName,
    questions,
    selectedAreas,
    selectedQuestions
  ) => {
    const area = focusAreas.find(
      (focusArea) => focusArea.focusArea === areaName
    );

    if (area && selectedAreas.includes(area._id)) {
      return (
        <>
          <div className="flex ">
            <Text className="mr-3 mt-1 mb-6 text-3xl">
              Preguntas de {areaName}
              <span className="text-red-500 text-md">(min. 2 - max. 4)</span>
            </Text>
          </div>

          {questions?.map((question, index) => (
            <Form.Item key={index} className="w-100">
              <Checkbox
                value={question._id}
                onChange={() => handleQuestions(question._id)}
                checked={selectedQuestions.includes(question._id)}
              >
                {question.question}
              </Checkbox>
            </Form.Item>
          ))}
        </>
      );
    }

    return null;
  };

  // desactivar boton de guardar o actualizar cuando no se escribio nada

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    // Verificar si el input está vacío o igual al valor original en el caso de edición
    const isNameEmpty =
      !values.name || (programsData && values.name === programsData.name);

    // Habilitar/deshabilitar el botón según la condición
    setIsButtonDisabled(isNameEmpty);
  }, [values.name, programsData]);

  useEffect(() => {
    // Verificar si el select de company está vacío o igual al valor original en el caso de edición
    const isCompanySelectEmpty =
      !values.company ||
      (programsData && values.company === programsData.company?._id);

    // Habilitar/deshabilitar el botón según la condición
    setIsButtonDisabled(isCompanySelectEmpty);
  }, [values.company, programsData]);

  useEffect(() => {
    // Verificar si el select de status está vacío o igual al valor original en el caso de edición
    const isStatusSelectEmpty =
      !values.status || (programsData && values.status === programsData.status);

    // Habilitar/deshabilitar el botón según la condición
    setIsButtonDisabled(isStatusSelectEmpty);
  }, [values.status, programsData]);

  // programa general o particular

  const [particularProgram, setParticularProgram] = useState(true);

  const handleProgramChange = (e) => {
    setParticularProgram(e.target.value === "particular");
  };

  return (
    <div className="Coaches">
      <h2 className="title_table">
        <TitleCustom />
      </h2>
      <Button
        type="primary"
        onClick={() => {
          router(-1);
        }}
      >
        REGRESAR
      </Button>
      <Card>
        <Form
          className="flex"
          layout="vertical"
          form={form}
          name="AddCoach control-ref"
          onFinish={handleSubmit}
        >
          <div className="flex pl-5 column w-100">
            <div className="mb-5">
              <div className="flex  flex-wrap">
                <div className="flex flex-col justify-center mb-12 mt-4">
                  <Text className="mb-3">Selecciona tipo de programa</Text>
                  <div className="mt-1">
                    <Radio.Group
                      onChange={handleProgramChange}
                      value={particularProgram ? "particular" : "general"}
                      size="large"
                    >
                      <Radio value="particular">Particular</Radio>
                      <Radio value="general">General</Radio>
                    </Radio.Group>
                  </div>
                </div>

                <div className="flex mr-8 column w-100 ">
                  <Text className="mb-3">Nombre de programa</Text>
                  <Input
                    name="name"
                    className="!mb-9"
                    style={{ width: "15vw" }}
                    placeholder="Nombre.."
                    onChange={(e) => {
                      handleChange(e);
                      setIsButtonDisabled(!e.target.value.trim());
                    }}
                    value={values.name}
                  />
                </div>

                <div className="flex mb-4">
                  {particularProgram && (
                    <Form.Item name="company" label="Compañia">
                      <Select
                        id="company"
                        placeholder="Select a option"
                        onChange={(e) => {
                          // GetCohorts(e);
                          setFieldValue("company", e);
                        }}
                        value={values.company}
                        allowClear
                        options={companies}
                      ></Select>
                    </Form.Item>
                  )}

                  <Form.Item
                    name="status"
                    label="Status"
                    style={{ marginLeft: particularProgram ? "1vw" : 0 }}
                  >
                    <Select
                      id="status"
                      placeholder="Select a option"
                      onChange={(e) => {
                        console.log("E", e);
                        setFieldValue("status", e);
                      }}
                      value={values.status}
                      allowClear
                    >
                      <Select.Option value="active">Activo</Select.Option>
                      {/* <Select.Option value="inprocess">
                        En Proceso
                      </Select.Option> */}
                      <Select.Option value="finished">Finalizado</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="limitCoaches"
                    label="Limitar 3 coachees por coach"
                    style={{ marginLeft: particularProgram ? "1vw" : 0 }}
                  >
                    <Select
                      id="limitCoaches"
                      placeholder="Select a option"
                      onChange={(e) => {
                        setFieldValue("limitCoaches", e);
                      }}
                      value={values.limitCoaches}
                      allowClear
                    >
                      <Select.Option value={true}>Activar</Select.Option>
                      <Select.Option value={false}>Desactivar</Select.Option>
                    </Select>
                  </Form.Item>
                </div>

                {window.location.pathname.includes("edit") ? null : (
                  <Form.Item
                    name="Áreas de foco"
                    className="Add__input w-full"
                    style={{ marginRight: 15 }}
                    label="Áreas de foco"
                  >
                    <div className="flex flex-wrap">
                      {options?.map((option) => (
                        <div
                          key={option.value}
                          className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 p-2"
                        >
                          <Checkbox
                            value={option.value}
                            onChange={() => handleFocusAreas(option.value)}
                            checked={selectedFocusAreas.includes(option.value)}
                          >
                            {option.label}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  </Form.Item>
                )}
              </div>
            </div>

            {window.location.pathname.includes("edit") ? null : (
              <>
                {focusAreas.map((area) => (
                  <React.Fragment key={area._id}>
                    {renderQuestionsForArea(
                      area.focusArea,
                      getQuestionsByFocusArea(area._id),
                      selectedFocusAreas,
                      selectedQuestions
                    )}
                  </React.Fragment>
                ))}
              </>
            )}

            <div className="flex justify-end">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                disabled={
                  isButtonDisabled && !window.location.pathname.includes("edit")
                }
              >
                {window.location.pathname.includes("edit")
                  ? "Actualizar"
                  : "Guardar"}
              </Button>
              <Button
                style={{ marginLeft: 20 }}
                size="large"
                htmlType="button"
                onClick={() => navigate(-1)}
              >
                Cancelar
              </Button>
            </div>
          </div>
        </Form>
      </Card>
    </div>
  );
}

export default AddProgram;
