import { AiOutlineClose } from "react-icons/ai";
import { useEffect } from "react";

const FilterOptions = ({
  setFechaInicial,
  setFechaFinal,
  fechaInicial,
  fechaFinal,
  setFinalizada,
  setCancelada,
  setAgendada,
  finalizada,
  cancelada,
  agendada,
  makeSearchTable,
  setFilters,
  filters,
  refresh,
}) => {
  const handleDate = () => {
    const newFilter = { ...filters, fechaInicial: null, fechaFinal: null };
    setFilters(newFilter);
    setFechaInicial(null);
    setFechaFinal(null);
    if (allFiltersInactive(newFilter)) {
      refresh();
    } else {
      makeSearchTable(newFilter);
    }
  };
  const handleFinalizadas = () => {
    const newFilter = { ...filters, finalizada: false };
    setFilters(newFilter);
    setFinalizada(false);
    if (allFiltersInactive(newFilter)) {
      refresh();
    } else {
      makeSearchTable(newFilter);
    }
  };

  const handleAgendadas = () => {
    const newFilter = { ...filters, agendada: false };
    setFilters(newFilter);
    setAgendada(false);
    if (allFiltersInactive(newFilter)) {
      refresh();
    } else {
      makeSearchTable(newFilter);
    }
  };

  const handleCanceladas = () => {
    const newFilter = { ...filters, cancelada: false };
    setFilters(newFilter);
    setCancelada(false);

    if (allFiltersInactive(newFilter)) {
      refresh();
    } else {
      makeSearchTable(newFilter);
    }
  };

  const allFiltersInactive = (filter) => {
    return Object.values(filter).every(
      (value) => value === false || value === null
    );
  };

  useEffect(() => {
    makeSearchTable(filters);
  }, []);

  return (
    <div>
      {fechaInicial && (
        <label
          onClick={handleDate}
          tabIndex={0}
          className="btn h-20 rounded-3xl bg-transparent hover:bg-transparent hover:underline hover:text-cyan-700 focus:text-cyan-700 text-lg ml-4 focus:border-blue-200"
        >
          Desde :{" "}
          <span className="text-cyan-700">
            {filters.fechaInicial.format("DD-MM-YYYY")}
          </span>{" "}
          hasta
          <span className="text-cyan-700">
            {filters.fechaFinal.format("DD-MM-YYYY")}
          </span>{" "}
          <span>
            <AiOutlineClose />
          </span>
        </label>
      )}

      {finalizada && (
        <label
          onClick={handleFinalizadas}
          tabIndex={0}
          className="btn h-20 rounded-3xl bg-transparent hover:bg-transparent hover:underline hover:text-cyan-700 focus:text-cyan-700 text-lg ml-4 focus:border-blue-200"
        >
          Finalizadas{" "}
          <span>
            <AiOutlineClose />
          </span>
        </label>
      )}

      {agendada && (
        <label
          onClick={() => handleAgendadas()}
          tabIndex={0}
          className="btn h-20 rounded-3xl bg-transparent hover:bg-transparent hover:underline hover:text-cyan-700 focus:text-cyan-700 text-lg ml-4 focus:border-blue-200"
        >
          Agendadas{" "}
          <span>
            <AiOutlineClose />
          </span>
        </label>
      )}

      {cancelada && (
        <label
          onClick={() => handleCanceladas()}
          tabIndex={0}
          className="btn h-20 rounded-3xl bg-transparent hover:bg-transparent hover:underline hover:text-cyan-700 focus:text-cyan-700 text-lg ml-4 focus:border-blue-200"
        >
          Canceladas{" "}
          <span>
            <AiOutlineClose />
          </span>
        </label>
      )}
    </div>
  );
};

export default FilterOptions;
