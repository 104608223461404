import React, { useEffect, lazy, Suspense } from "react";
import { auth, firestore } from "utilities/admin.firebase.utility";
import { resetUser, modifyUser } from "redux/slices/user";
import { onAuthStateChanged } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { getDoc, doc } from "firebase/firestore";
import { firebaseUserAdapter } from "adapters";
import "./scss/global.scss";
import Auth from "pages/Auth";
import Dashboard from "layouts/dashboard";
import { Routes, Route } from "react-router-dom";
import Home from "pages/Home";
import { CircularProgress } from "@chakra-ui/react";
import "antd/dist/antd.min.css";
import "react-phone-input-2/lib/style.css";
import Email from "pages/Email";
import AddEmail from "pages/Email/components/AddEmail";
import RefreshToken from "pages/RefreshToken";
import AddProgram from "pages/Programs/components/Program/AddProgram";
import NoShowSessions from "pages/SessionsSection/Tab/NoShowSessions";
import AddCoacheeExcel from "pages/Coachees/components/AddCoacheeExcel";
import CoacheesByCohort from "pages/Cohort/components/CoacheesByCohort";
import MidNps from "pages/MidNps";
import FinalNps from "pages/FinalNps";

// Routes
const Coachees = lazy(() => import("pages/Coachees"));
const TabCoachee = lazy(() => import("pages/Coachees/components/TabCoachee"));

const Coaches = lazy(() => import("pages/Coaches"));
const TabCoach = lazy(() => import("pages/Coaches/components/TabCoach"));

const FocusAreas = lazy(() => import("pages/FocusAreas"));
const AddFocusAreas = lazy(
  () => import("pages/FocusAreas/components/AddFocusAreas")
);

const Company = lazy(() => import("pages/Company"));
const AddCompany = lazy(() => import("pages/Company/components/TabCompany"));

const AddContact = lazy(
  () => import("pages/Company/components/TabContacts/components/Contact")
);

const Sessions = lazy(() => import("pages/Sessions"));
const EditableSessions = lazy(() => import("pages/Sessions/EditableSessions"));
const AddSessions = lazy(() => import("pages/Sessions/AddSession"));
const RescheduleCalendar = lazy(
  () => import("pages/Sessions/RescheduleSession")
);

const Cohort = lazy(() => import("pages/Cohort"));
const AddCohort = lazy(() => import("pages/Cohort/components/Cohort"));

const Admin = lazy(() => import("pages/Admin"));
const AddAdmin = lazy(() => import("pages/Admin/components/AddAdmin"));

const Reports = lazy(() => import("pages/Reports"));

const Questions = lazy(() => import("pages/Questions"));
const AddQuestions = lazy(
  () => import("pages/Questions/components/AddQuestions")
);

const SessionsSection = lazy(() => import("pages/SessionsSection"));

const Programs = lazy(() => import("pages/Programs"));
const TabProgram = lazy(() => import("pages/Programs/components/Tabs"));

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);

  useEffect(() => {
    onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const info = await getDoc(doc(firestore, "users", firebaseUser.uid));
        dispatch(modifyUser(firebaseUserAdapter(firebaseUser, info.data())));
      } else {
        dispatch(resetUser());
      }
    });
  }, []);

  const uid = localStorage.getItem("uid");

  return !uid ? (
    <Auth />
  ) : (
    <Dashboard>
      <Suspense fallback={<CircularProgress isIndeterminate />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/coachees" element={<Coachees />} />
          <Route path="/coachees/add" element={<TabCoachee />} />
          <Route path="/coachees/addExcel" element={<AddCoacheeExcel />} />
          <Route path="/coachees/edit/:id" element={<TabCoachee />} />
          <Route path="/coachees/view/:id" element={<TabCoachee />} />

          <Route path="/coaches" element={<Coaches />} />
          <Route path="/coaches/add" element={<TabCoach />} />
          <Route path="/coaches/edit/:id" element={<TabCoach />} />
          <Route path="/coaches/view/:id" element={<TabCoach />} />

          <Route path="/focusareas" element={<FocusAreas />} />
          <Route path="/focusareas/add" element={<AddFocusAreas />} />
          <Route path="/focusareas/edit/:id" element={<AddFocusAreas />} />
          <Route path="/focusareas/view/:id" element={<AddFocusAreas />} />

          <Route path="/companies" element={<Company />} />
          <Route path="/companies/add" element={<AddCompany />} />
          <Route path="/companies/edit/:id" element={<AddCompany />} />
          <Route path="/companies/view/:id" element={<AddCompany />} />

          <Route
            path="/companies/edit/:id/contacts/add"
            element={<AddContact />}
          />
          <Route
            path="/companies/edit/:id/contacts/edit/:idcontact"
            element={<AddContact />}
          />
          <Route
            path="/companies/edit/:id/contacts/view/:idcontact"
            element={<AddContact />}
          />
          <Route
            path="/companies/view/:id/contacts/add"
            element={<AddContact />}
          />
          <Route
            path="/companies/view/:id/contacts/edit/:idcontact"
            element={<AddContact />}
          />
          <Route
            path="/companies/view/:id/contacts/view/:idcontact"
            element={<AddContact />}
          />

          <Route path="/cohorts" element={<Cohort />} />
          <Route path="/cohorts/add" element={<AddCohort />} />
          <Route path="/cohorts/edit/:id" element={<AddCohort />} />
          <Route path="/cohorts/view/:id" element={<AddCohort />} />
          <Route
            path="/cohorts/coachees/view/:id"
            element={<CoacheesByCohort />}
          />

          <Route path="/admins" element={<Admin />} />
          <Route path="/admins/add" element={<AddAdmin />} />
          <Route path="/admins/edit/:id" element={<AddAdmin />} />
          <Route path="/admins/view/:id" element={<AddAdmin />} />

          <Route path="/questions" element={<Questions />} />
          <Route path="/questions/add" element={<AddQuestions />} />
          <Route path="/questions/edit/:id" element={<AddQuestions />} />
          <Route path="/questions/view/:id" element={<AddQuestions />} />

          <Route path="/emails" element={<Email />} />
          <Route path="/emails/add" element={<AddEmail />} />
          <Route path="/emails/edit/:id" element={<AddEmail />} />
          <Route path="/emails/view/:id" element={<AddEmail />} />

          <Route path="/coachee/session/view/:id" element={<Sessions />} />
          <Route
            path="/coachee/session/edit/:id"
            element={<EditableSessions />}
          />
          <Route
            path="/coachee/session/addSession/:id"
            element={<AddSessions />}
          />
          <Route
            path="/coachee/session/editSession/:id"
            element={<RescheduleCalendar />}
          />

          <Route path="/sessionsSection" element={<SessionsSection />} />
          <Route path="/sessionsSection/noShow" element={<NoShowSessions />} />

          <Route path="/programs" element={<Programs />} />
          <Route path="/programs/add" element={<AddProgram />} />
          <Route path="/programs/program/view/:id" element={<TabProgram />} />
          <Route path="/programs/program/edit/:id" element={<TabProgram />} />

          <Route path="/RefreshToken" element={<RefreshToken />} />

          <Route path="/reports" element={<Reports />} />
          <Route path="/midnps" element={<MidNps />} />
          <Route path="/finalnps" element={<FinalNps />} />
        </Routes>
      </Suspense>
    </Dashboard>
  );
}

export default App;
