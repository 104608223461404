import axios from "axios";
import Coache from "models/Coache";
import { loadAbort } from "../utilities";

const route = process.env.REACT_APP_API_URL + "coachee";

// eslint-disable-next-line import/prefer-default-export
export const post = (data) => {
  const controller = loadAbort();
  return {
    call: () => axios.post(route, { ...data }, { signal: controller.signal }),
    controller,
  };
};

export const getAll = (
  companyId: string = null,
  page: number = 1,
  pageSize: number = 10
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(
        companyId
          ? `${route}/GetAllDashboard?companyId=${companyId}&page=${page}&pageSize=${pageSize}`
          : `${route}/GetAllDashboard?page=${page}&pageSize=${pageSize}`,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getAllToExport = () => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/GetAllDashboard`, { signal: controller.signal }),
    controller,
  };
};

export const getActiveCoachees = (page: number = 1, pageSize: number = 10) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(
        `${route}/GetActiveCoachees?page=${page}&pageSize=${pageSize}`,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getUnActiveCoachees = (
  page: number = 1,
  pageSize: number = 10
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(
        `${route}/GetUnActiveCoachees?page=${page}&pageSize=${pageSize}`,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getAlphabeticalCoachees = (
  page: number = 1,
  pageSize: number = 10,
  alphabeticalOrder
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(
        `${route}/GetAlphabeticalCoachees?page=${page}&pageSize=${pageSize}&alphabeticalOrder=${alphabeticalOrder}`,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const GetAllCoachees = (companyId: string = null) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(
        companyId
          ? `${route}/GetAllCoachees?companyId=${companyId}`
          : `${route}/GetAllCoachees`,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getAllWithFilters = (
  page: number = 1,
  pageSize: number = 10,
  text: any
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.patch(
        `${route}/GetAllDashboardWithFilters?page=${page}&pageSize=${pageSize}&searchQuery=${text}`,
        null,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getSearchWithFilters = (
  page: number = 1,
  pageSize: number = 10,
  text: any
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.patch(
        `${route}/GetSearchWithFilters?page=${page}&pageSize=${pageSize}&searchQuery=${text}`,
        null,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getByCoach = (coach: string) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/coach?coach=${coach}`, { signal: controller.signal }),
    controller,
  };
};

export const getByCohortId = (id: string) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/GetByCohort/${id}`, { signal: controller.signal }),
    controller,
  };
};

export const getByProgramId = (id: string) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/GetByProgram/${id}`, { signal: controller.signal }),
    controller,
  };
};

export const getById = (id: string) => {
  const controller = loadAbort();
  return {
    call: () => axios.get(`${route}/${id}`, { signal: controller.signal }),
    controller,
  };
};

export const userStatus = (id, data) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.put(
        `${route}/userStatus/${id}`,
        { ...data },
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getByCoacheeId = (id: string) => {
  const controller = loadAbort();
  return {
    call: () => axios.get(`${route}/${id}`, { signal: controller.signal }),
    controller,
  };
};

export const remove = (id) => {
  const controller = loadAbort();
  return {
    call: () => axios.delete(`${route}/${id}`, { signal: controller.signal }),
    controller,
  };
};

export const updateAdditionalSessions = (id, data) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.put(
        `${route}/additionalSessions/${id}`,
        { ...data },
        { signal: controller.signal }
      ),
    controller,
  };
};

export const updateCoach = (id, data) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.put(
        `${route}/updateCoach/${id}`,
        { ...data },
        { signal: controller.signal }
      ),
    controller,
  };
};

export const put = (id, data) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.put(
        `${route}/status/${id}`,
        { ...data },
        { signal: controller.signal }
      ),
    controller,
  };
};

export const addCoacheeByExcel = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.post(`${route}/uploadCoachees`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        signal: controller.signal,
      }),
    controller,
  };
};

export default {
  post,
  remove,
  put,
};
