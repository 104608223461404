import {
  Button,
  Divider,
  Form,
  Input,
  List,
  Popconfirm,
  Select,
  message,
} from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { RcFile } from "antd/lib/upload";
import { InboxOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import useFetchAndLoad from "hooks/useFetchAndLoad";
import { getAll as GetAllCompanies } from "services/companies.service";
import { getByCompany } from "services/cohort.service";
import { getAllPrograms } from "services/program.service";
import { UploadFile } from "antd/lib/upload/interface";
import { UploadProps } from "antd/es/upload";
import { addCoacheeByExcel } from "services/coachee.service";

const AddCoacheeExcel = () => {
  const [form] = Form.useForm();
  const [companies, setCompanies] = useState([]);
  const [cohorts, setCohorts] = useState([]);
  const [programs, setPrograms] = useState([]);
  const { loading, callEndpoint } = useFetchAndLoad();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [resultOfCreate, setResultOfCreate] = useState([]);

  const initialValues = () => ({
    company: null,
    cohort: null,
    program: null,
  });

  const GetCompanies = async () => {
    const { data } = await callEndpoint(GetAllCompanies());
    if (Array.isArray(data.data) && data.data.length > 0) {
      setCompanies(
        data.data.map((x) => ({
          value: x._id,
          label: (
            <div className="flex align-center">
              {x && x.urlImgCompany ? (
                <img
                  className="mr-2"
                  width="30"
                  height="30"
                  src={x.urlImgCompany}
                  alt={x.name}
                />
              ) : (
                ""
              )}
              {x ? x.name : ""}
            </div>
          ),
        }))
      );
    }
  };

  const GetCohorts = async (id: string) => {
    const { data } = await callEndpoint(getByCompany(id));
    if (Array.isArray(data.data) && data.data.length > 0) {
      setCohorts(
        data.data.map((x) => ({
          value: x._id,
          label: x.Name,
        }))
      );
    }
  };

  const getPrograms = async (e) => {
    try {
      const { data } = await callEndpoint(getAllPrograms());

      let companySelected = e;

      const filteredProgramsByCompany = data?.data?.result?.filter(
        (program) => {
          return program.company?.[0]?._id === companySelected;
        }
      );

      const generalPrograms = data?.data?.result?.filter((program) => {
        return program.company === null;
      });

      const allPrograms = [...filteredProgramsByCompany, ...generalPrograms];

      setPrograms(
        allPrograms?.map((x) => ({
          value: x._id,
          label: x.name,
        })) || []
      );
    } catch (error) {
      console.error("Error fetching programs:", error);
    }
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },

    beforeUpload: (file) => {
      setFileList([file]);

      return false;
    },
    fileList,
  };

  const {
    handleSubmit,
    setFieldValue,
    handleChange,
    values,
    handleBlur,
    resetForm,
  } = useFormik({
    initialValues: initialValues(),
    onSubmit: async (form, { resetForm }) => {
      try {
        if (fileList.length < 1) {
          message.error("Necesitas cargar el archivo de excel");
          return;
        }

        const formData = new FormData();

        formData.append("coachees", fileList[0] as any);
        formData.append("cohort", form.cohort);
        formData.append("program", form.program);
        formData.append("company", form.company);

        const { data } = await callEndpoint(addCoacheeByExcel(formData));
        setResultOfCreate(data.data);

        message.success("Carga Completa!");
      } catch (error) {
        message.error(`Error al cargar excel ${error}`);
      }
    },
  });

  useEffect(() => {
    GetCompanies();
  }, []);

  return (
    <div className="AddCoacheeExcel">
      <h1>Agregar Coachees por Excel</h1>
      <div className="Content">
        <Form
          className="AddCoacheeExcel__Form"
          layout="vertical"
          form={form}
          name="AddCoacheeExcel control-ref"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="company"
            label="Compañia"
            rules={[
              {
                required: true,
                message: "Por favor seleccione su compañia.",
              },
            ]}
            className="AddCoacheeExcel__Form__item"
          >
            <Select
              id="company"
              placeholder="Selecciona una compañia"
              onChange={async (e) => {
                GetCohorts(e);
                getPrograms(e);
                setFieldValue("company", e);
              }}
              value={values.company}
              allowClear
              options={companies}
              showSearch
              filterOption={(input, option) =>
                (option?.label.props.children[1] ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item
            name="cohort"
            label="Cohorte"
            rules={[
              { required: true, message: "Por favor seleccione cohorte." },
            ]}
            className="AddCoacheeExcel__Form__item"
          >
            <Select
              id="cohort"
              placeholder="Selecciona una Cohorte"
              onChange={(e) => {
                setFieldValue("cohort", e);
              }}
              value={values.cohort}
              allowClear
              options={cohorts}
            />
          </Form.Item>

          <Form.Item
            name="program"
            label="Programa"
            rules={[
              {
                required: true,
                message: "Por favor seleccione su Programa.",
              },
            ]}
            className="AddCoacheeExcel__Form__item"
          >
            <Select
              id="program"
              placeholder="Selecciona un programa"
              onChange={(e) => {
                setFieldValue("program", e);
              }}
              value={values.program}
              allowClear
              options={programs}
            ></Select>
          </Form.Item>
        </Form>

        <Dragger
          name={"coachees"}
          maxCount={1}
          accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, application/vnd.apple.numbers"
          {...props}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Da click o arrastra un archivo para subirlo
          </p>
          <p className="ant-upload-hint">Support for a single upload.</p>
        </Dragger>

        <Popconfirm
          title={"Cargar Excel?"}
          onConfirm={() => form.submit()}
          okText="Cargar"
        >
          <Button
            size="large"
            loading={loading}
            type="primary"
            htmlType="submit"
            className="AddCoacheeExcel__Button"
            disabled={fileList.length === 0}
            form="AddCoacheeExcel control-ref"
          >
            Cargar Excel
          </Button>
        </Popconfirm>

        <Divider>Información de la carga</Divider>

        <div className="AddCoacheeExcel__info">
          <List
            itemLayout="horizontal"
            dataSource={resultOfCreate}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    item.error ? (
                      <p className="AddCoacheeExcel__info_error">
                        {item.message}
                      </p>
                    ) : (
                      <p className="AddCoacheeExcel__info_success">
                        {item.message}
                      </p>
                    )
                  }
                  description={item?.error}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default AddCoacheeExcel;
