import React, { useEffect, useState } from "react";
import BaseTable from "components/base/base-table";
import useFetchAndLoad from "hooks/useFetchAndLoad";
import { Excel } from "antd-table-saveas-excel";
import {
  getAllDashbordMidNps,
  getMidNpsByRangeOfDates,
} from "services/midnps.service";
import moment, { Moment } from "moment";
import { DatePicker } from "antd";
import displayToast from "utilities/toast.utility";

function MidNps() {
  const { RangePicker } = DatePicker;
  const [text, setText] = useState("");

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [SelectedItems, setSelectedItems] = useState<any[]>([]);

  const { loading, callEndpoint } = useFetchAndLoad();

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    const arr = [];
    const result = data.filter((x) => newSelectedRowKeys.includes(x._id));
    for (const item of result) {
      arr.push({
        ...item,
        // evaluation:
        //   item.RatingCoach && item?.RatingCoach?.length > 0
        //     ? item?.RatingCoach?.reduce((a, b) => a + b.Score, 0) /
        //       item?.RatingCoach?.length
        //     : 5,
        // status: item?.statusUser ? "ACTIVO" : "INACTIVO",
        // coachees: item?.coachees?.length,
      });
    }
    setSelectedItems(arr);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getData = async (page: number = 1, pageSize: number = 30) => {
    const { data } = await callEndpoint(getAllDashbordMidNps(page, pageSize));

    setData(
      data?.data?.result?.map((x) => {
        return {
          ...x,
          key: x.id,
        };
      })
    );
    setTotal(data?.data?.total);
  };

  // const getAllCompanies = async () => {
  //   const { data } = await callEndpoint(getAll());

  //   setAllCompanies(
  //     data?.data?.map((x, i) => {
  //       x.key = x._id;
  //       return x;
  //     })
  //   );
  // };

  useEffect(() => {
    getData();
    // getAllCompanies();
  }, []);

  const columns = [
    {
      title: "Coachee",
      dataIndex: "coachee",
      width: "10%",
      align: "center",
      render: (coachee) => `${coachee.name} ${coachee.lastname}`,
    },
    {
      title: "Compañía",
      dataIndex: "coachee",
      width: "10%",
      align: "center",
      render: (coachee) =>
        coachee?.company.name ? coachee.company.name : "Error",
    },
    {
      title: "¿Recomendarías Bonum Coaching?",
      dataIndex: "recomendation",
      align: "center",
      showSorterTooltip: { target: "full-header" },
      sorter: {
        compare: (a, b) => a.recomendation - b.recomendation,
        multiple: 1,
      },
      // editable: true,
      // searchable: true,
    },
    {
      title:
        "¿Hay algún aspecto del programa que encuentras útil pero que crees que podría ser mejorado?",
      dataIndex: "usefulAspect",
      align: "center",
      // editable: true
    },
    {
      title:
        "Existen obstáculos o problemas técnicos del programa que dificultan tu proceso de coaching?",
      dataIndex: "obstacles",
      align: "center",
    },
    {
      title: "Fecha",
      dataIndex: "createdAt",
      align: "center",
      render: (date) => moment(new Date(date)).format("DD/MMM/YYYY"),
      sorter: {
        compare: (a, b) => {
          const aDate = moment(a.createdAt).unix();
          const bDate = moment(b.createdAt).unix();
          return aDate - bDate;
        },
        multiple: 2,
      },
    },
  ];

  const refresh = () => {
    setText("");
    getData();
  };

  const columnsExport = [
    {
      title: "Coachee",
      dataIndex: "coachee",
      render: (coachee) => `${coachee.name} ${coachee.lastname}`,
    },
    {
      title: "Compañía",
      dataIndex: "coachee",
      render: (coachee) =>
        coachee?.company.name ? coachee.company.name : "Error",
    },
    {
      title: "¿Recomendarías Bonum Coaching?",
      dataIndex: "recomendation",
      // editable: true,
      // searchable: true,
    },
    {
      title:
        "¿Hay algún aspecto del programa que encuentras útil pero que crees que podría ser mejorado?",
      dataIndex: "usefulAspect",
      // editable: true
    },
    {
      title:
        "Existen obstáculos o problemas técnicos del programa que dificultan tu proceso de coaching?",
      dataIndex: "obstacles",
    },
    {
      title: "Fecha",
      dataIndex: "createdAt",
      render: (date) => moment(new Date(date)).format("DD/MMM/YYYY"),
    },
  ];

  const handleClick = () => {
    const excel = new Excel();

    excel
      .setTHeadStyle({
        height: 10,
        background: "57B0F3",
        color: "ffffff",
        wrapText: true,
        bold: true,
        shrinkToFit: false,
      })
      .addSheet("MidNps")
      .addColumns(columnsExport)
      .addDataSource(data, {
        str2Percent: true,
      })
      .saveAs("MidNps.xlsx");
  };

  const handleRangePickerChange = async (value: Moment[]) => {
    try {
      const start = value[0]
        .set("hour", 0)
        .set("hour", 0)
        .set("minutes", 0)
        .toDate()
        .toUTCString();

      const end = value[1]
        .set("hour", 23)
        .set("minutes", 59)
        .toDate()
        .toUTCString();

      const { data } = await callEndpoint(getMidNpsByRangeOfDates(start, end));

      setData(
        data?.data?.result?.map((x) => {
          return {
            ...x,
            key: x.id,
          };
        })
      );
      setTotal(data?.data?.total);
    } catch (error) {
      displayToast("Error al cargar datos", "error");
    }
  };

  return (
    <div className="Coachees">
      <h2 className="flex justify-center">Mid NPS</h2>
      <div className="focusAreas__content Content">
        <div className="Coachees__rangepicker">
          <p>Selecciona el rango de fechas para exportar en el NPS</p>
          <RangePicker onChange={handleRangePickerChange} />
        </div>
        <BaseTable
          loading={loading}
          originData={data}
          columns={columns}
          exportMethod={handleClick}
          hasSelected={true}
          refresh={refresh}
          value={text}
          total={total}
          addTitle={"Add Company"}
        />
      </div>
    </div>
  );
}

export default MidNps;
