import { BiExport } from "react-icons/bi";

const ExportAll = ({ exportAllData, hasSelected }) => {
  return (
    <>
      {!hasSelected ? (
        <button className="dropdown" onClick={() => exportAllData()}>
          <div className="ml-4">
            <span className="relative flex justify-center items-center">
              <span className=" ">
                <BiExport className="text-[#46d5a5]" size={25} />
              </span>
              <span className="ml-2 mt-3 text-[#46d5a5] text-xl uppercase underline">
                Exportar todos
              </span>
            </span>
          </div>
        </button>
      ) : (
        <button>
          <div className="ml-4">
            <span className="relative flex justify-center items-center">
              <span className="">
                <BiExport size={25} />
              </span>
              <span className="ml-2 mt-3 text-gray-500 text-xl uppercase underline">
                Exportar todos
              </span>
            </span>
          </div>
        </button>
      )}
    </>
  );
};

export default ExportAll;
