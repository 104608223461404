import axios from "axios";
import { loadAbort } from "utilities";

const route = process.env.REACT_APP_API_URL + "program";

export const getAllPrograms = () => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.get(`${route}/GetAllPrograms`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const getAllProgramsDashboard = (
  page: number = 1,
  pageSize: number = 10
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(
        `${route}/GetAllProgramsDashboard?page=${page}&pageSize=${pageSize}`,
        {
          signal: controller.signal,
        }
      ),
    controller,
  };
};

export const getSearchWithFilters = (
  page: number = 1,
  pageSize: number = 10,
  filters: any
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.patch(
        `${route}/GetSearchWithFilters?page=${page}&pageSize=${pageSize}`,
        filters,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getSearchByText = (
  page: number = 1,
  pageSize: number = 10,
  text: any
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.patch(
        `${route}/SearchByText?page=${page}&pageSize=${pageSize}&searchQuery=${text}`,
        null,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const addProgram = (data) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.post(
        `${route}/AddProgram`,
        { ...data },
        { signal: controller.signal }
      ),
    controller,
  };
};

export const editProgram = (programId, data) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.put(
        `${route}/editProgram/${programId}`,
        { ...data },
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getProgramById = (id: string) => {
  const controller = loadAbort();
  return {
    call: () => axios.get(`${route}/${id}`, { signal: controller.signal }),
    controller,
  };
};

export const removeProgram = (id) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.delete(`${route}/delete/${id}`, {
        signal: controller.signal,
      }),
    controller,
  };
};
