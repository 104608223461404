import { DatePicker } from "antd";
import { useState } from "react";
import CoachButton from "./CoachButton";
import CoacheeButton from "./CoacheeButton";
import FilterButton from "./FilterButton";

const FiltersButton = ({
  setFechaInicial,
  setFechaFinal,
  fechaInicial,
  fechaFinal,
  setFinalizada,
  setCancelada,
  setAgendada,
  finalizada,
  cancelada,
  agendada,
  makeSearchTable,
  setApplyFilters,
  setFilters,
  setCoachModal,
  setCoacheeModal,
  noShow,
}) => {
  const [showDateFilter, setShowDateFilter] = useState(false);
  const [showStatusFilter, setShowStatusFilter] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleDropdown = () => {
    setDropdownOpen(false);
  };

  return (
    <div className="dropdown ">
      <label
        tabIndex={0}
        onClick={() => setDropdownOpen(!isDropdownOpen)}
        className="btn h-20 rounded-3xl bg-transparent hover:bg-transparent hover:underline hover:text-cyan-700 focus:text-cyan-700 text-lg ml-4 focus:border-blue-200"
      >
        Agregar filtros
      </label>
      {isDropdownOpen && (
        <div
          tabIndex={0}
          className="dropdown-content z-10 card card-compact w-96 p-2 shadow text-primary-content mt-6 bg-white"
        >
          <div className="card-body">
            <h3 className="card-title flex justify-center content-center">
              <span className=" text-center">Filtros</span>
            </h3>
            <div
              className={`${
                showDateFilter || showStatusFilter
                  ? "mt-4 flex justify-center gap-2 flex-wrap"
                  : "mt-4 flex justify-center gap-2 flex-wrap"
              }`}
            >
              <CoachButton setCoachModal={setCoachModal} />

              <CoacheeButton setCoacheeModal={setCoacheeModal} />

              <FilterButton
                title="Fecha"
                showDateFilter={showDateFilter}
                setShowDateFilter={setShowDateFilter}
              />

              {showDateFilter && (
                <>
                  <div className="">
                    <span className="ml-1 text-lg font-bold mb-3 text-center text-black">
                      Desde
                    </span>
                    <DatePicker
                      name="StartDate"
                      className="!mb-9 AddCoach__input_control w-100 z-20"
                      placeholder="Fecha"
                      clearIcon={false}
                      // value={fechaInicial}
                      onChange={(e) => {
                        setFechaInicial(e);
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          fechaInicial: e,
                        }));
                      }}
                      style={{ borderRadius: "20px" }}
                    />
                    <span className="ml-1 text-lg font-bold mb-3 text-center text-black">
                      Hasta
                    </span>
                    <DatePicker
                      name="EndDate"
                      className="!mb-9 AddCoach__input_control w-100 z-20"
                      placeholder="Fecha"
                      clearIcon={false}
                      // value={fechaFinal}
                      onChange={(e) => {
                        setFechaFinal(e);
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          fechaFinal: e,
                        }));
                      }}
                      style={{ borderRadius: "20px" }}
                    />
                  </div>
                </>
              )}

              {!noShow && (
                <FilterButton
                  title="Status"
                  showStatusFilter={showStatusFilter}
                  setShowStatusFilter={setShowStatusFilter}
                />
              )}

              {showStatusFilter && (
                <div className="flex flex-wrap gap-6 justify-start items-start">
                  <div className="form-control my-1 w-1/2">
                    <label className="label cursor-pointer">
                      <span className="bg-green-200 text-green-600 py-1 px-3 rounded-full text-xl ">
                        Finalizadas
                      </span>
                      <input
                        type="checkbox"
                        // className="checkbox checkbox-info"
                        style={{
                          backgroundColor: finalizada ? "#1890ff" : "",
                          borderColor: finalizada ? "#1890ff" : "",
                          width: "15px",
                          height: "15px",
                          borderRadius: "20px",
                          cursor: "pointer",
                        }}
                        checked={finalizada}
                        onChange={(e) => {
                          setFinalizada(e.target.checked);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            finalizada: e.target.checked,
                          }));
                        }}
                      />
                    </label>
                  </div>

                  <div className="form-control w-1/2">
                    <label className="label cursor-pointer">
                      <span className="bg-yellow-200 text-yellow-600 py-1 px-3 rounded-full text-xl">
                        Agendadas
                      </span>
                      <input
                        type="checkbox"
                        // className="checkbox checkbox-primary"
                        style={{
                          backgroundColor: agendada ? "#1890ff" : "",
                          borderColor: agendada ? "#1890ff" : "",
                          width: "15px",
                          height: "15px",
                          borderRadius: "20px",
                          cursor: "pointer",
                        }}
                        checked={agendada}
                        onChange={(e) => {
                          setAgendada(e.target.checked);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            agendada: e.target.checked,
                          }));
                        }}
                      />
                    </label>
                  </div>

                  <div className="form-control mb-1 w-1/2">
                    <label className="label cursor-pointer">
                      <span className="bg-red-200 text-red-600 py-1 px-3 rounded-full text-xl">
                        Canceladas
                      </span>
                      <input
                        type="checkbox"
                        // className="checkbox checkbox-primary"
                        style={{
                          backgroundColor: cancelada ? "#1890ff" : "",
                          borderColor: cancelada ? "#1890ff" : "",
                          width: "15px",
                          height: "15px",
                          borderRadius: "20px",
                          cursor: "pointer",
                        }}
                        checked={cancelada}
                        onChange={(e) => {
                          setCancelada(e.target.checked);
                          setFilters((prevFilters) => ({
                            ...prevFilters,
                            cancelada: e.target.checked,
                          }));
                        }}
                      />
                    </label>
                  </div>
                </div>
              )}
            </div>
            {showDateFilter || showStatusFilter ? (
              fechaInicial ||
              fechaFinal ||
              finalizada ||
              agendada ||
              cancelada ? (
                <div className="flex items-center justify-center mt-12">
                  <FilterButton
                    title="Aplicar Filtros"
                    makeSearchTable={makeSearchTable}
                    setApplyFilters={setApplyFilters}
                    handleDropdown={handleDropdown}
                  />
                </div>
              ) : null
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default FiltersButton;
