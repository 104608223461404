import { useEffect } from "react";
import useFetchAndLoad from "hooks/useFetchAndLoad";
import { GetAllCoachees } from "services/coachee.service";
import displayToast from "utilities/toast.utility";
import Loading from "common/Loading/Loading";
import { useState } from "react";
import {
  coacheeSessionsWithNoShow,
  getByCoacheeId2,
} from "services/session.service";

const ModalCoachee = ({
  setCoacheeModal,
  setSessions,
  setTotal,
  setCoacheeId,
  setSelectedCoachee,
  selectedCoachee,
  noShow,
  setSessionsToExport,
}) => {
  const [coachees, setCoachees] = useState([]);
  const [loading, setLoading] = useState(false);

  const { callEndpoint } = useFetchAndLoad();

  const handleSubmit = async () => {
    try {
      setCoacheeId(selectedCoachee);
      setLoading(true);

      if (noShow) {
        const { data } = await callEndpoint(
          coacheeSessionsWithNoShow(1, 1000, selectedCoachee)
        );

        if (data.data.total > 0) {
          const newData = data?.data.result.data.map((x, i) => {
            x.key = x._id;
            return x;
          });

          setSessions([...newData]);
          setSessionsToExport(newData);
          setTotal(data.data.total);
        }
      } else {
        const { data } = await callEndpoint(
          getByCoacheeId2(1, 1000, selectedCoachee)
        );

        if (data.data.total > 0) {
          const newData = data?.data.result.data.map((x, i) => {
            x.key = x._id;
            return x;
          });

          setSessions([...newData]);
          setSessionsToExport(newData);
          setTotal(data.data.total);
        }
      }

      setLoading(false);

      displayToast("Coachee cargado con éxito", "success");

      await setCoacheeModal(false);
    } catch (error) {
      displayToast("El coachee no se pudo cargar", "error");

      setLoading(false);
    }
  };

  const getAllCoachees = async () => {
    try {
      setLoading(true);

      const { data } = await callEndpoint(GetAllCoachees());

      console.log("data", data);

      setCoachees(data.data.result);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCoachees();
  }, []);

  if (loading === false) {
    return (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-auto my-6 mx-auto max-w-3xl">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-[30vw] bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="mb-4 flex justify-between">
                <div></div>
                <button
                  type="button"
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                  onClick={() => setCoacheeModal(false)}
                >
                  <svg
                    aria-hidden="true"
                    className="w-10 h-10"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/*body*/}
              <div className="p-6 text-center">
                <h3 className="text-3xl mb-8 font-normal text-gray-500 text-center">
                  Selecciona uno de los siguientes coachees
                </h3>
                <div className="flex justify-center items-center mb-10">
                  <select
                    className="py-6 block w-7/12 border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500 text-3xl text-center text-black-700 outline outline-offset-2 outline-1"
                    onChange={(e) => setSelectedCoachee(e.target.value)}
                  >
                    {coachees.map((item, i) => (
                      <option key={i} value={item._id}>
                        {item?.name} {item?.lastname}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex justify-center space-x-6 mb-12">
                  <button
                    className="cursor-pointer"
                    onClick={() => handleSubmit()}
                  >
                    <div className="px-5 w-36 py-2.5 relative rounded-2xl group text-white font-medium inline-block h-12 text-center">
                      <span className="absolute top-0 left-0 w-full h-full rounded-2xl filter bg-[#1890ff]"></span>
                      <span className="relative flex items-center justify-center h-full w-full text-xl rounded-2xl">
                        Buscar
                      </span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer"
                    onClick={() => setCoacheeModal(false)}
                  >
                    <div className="px-5 w-36 py-2.5 relative rounded-2xl group text-white font-medium inline-block h-12 text-center">
                      <span className="absolute top-0 left-0 w-full h-full rounded-2xl filter bg-[#ff2600]"></span>
                      <span className="relative flex items-center justify-center h-full w-full text-xl rounded-2xl">
                        Cancelar
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    );
  }

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none px-64 py-24">
            {/*body*/}
            <div className="p-6 text-center">
              <div className="">
                <Loading />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default ModalCoachee;
