import { BiExport } from "react-icons/bi";

const Export = ({ handleClick, hasSelected }) => {
  return (
    <>
      {hasSelected ? (
        <button className="dropdown" onClick={() => handleClick()}>
          <div>
            <span className="relative flex justify-center items-center">
              <span className=" ">
                <BiExport className="text-[#46d5a5]" size={20} />
              </span>
              <span className="ml-2 mt-3 text-[#46d5a5] text-xl uppercase underline ">
                Exportar
              </span>
            </span>
          </div>
        </button>
      ) : (
        <button>
          <div>
            <span className="relative flex justify-center items-center">
              <span className="">
                <BiExport size={20} />
              </span>
              <span className="ml-2 mt-3 text-gray-500 text-xl uppercase underline ">
                Exportar
              </span>
            </span>
          </div>
        </button>
      )}
    </>
  );
};

export default Export;
