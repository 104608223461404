import React, { useEffect, useState } from "react";
import {
  Table,
  Input,
  Button,
  notification,
  Form,
  Typography,
  Space,
  Modal,
} from "antd";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  ReloadOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { FaUserAlt, FaUserAltSlash } from "react-icons/fa";
import BaseModel from "../../models/base.model";
import useFetchAndLoad from "../../hooks/useFetchAndLoad";
import { ColumnType, FilterConfirmProps } from "antd/lib/table/interface";
import "./NewTable.scss";
import ExportAll from "common/Buttons/ExportAll";
import Export from "common/Buttons/Export";

interface DataType {
  key: string;
}
type DataIndex = keyof DataType;

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  Type: "number" | "text" | "image" | "boolean" | "phone";
  record: BaseModel;
  index: number;
  children: React.ReactNode;
}

const TypeCell: React.FC<EditableCellProps> = ({
  dataIndex,
  title,
  Type,
  record,
  index,
  children,
  ...restProps
}) => {
  let custom = null;
  switch (Type) {
    case "image":
      custom = (
        <img
          width={100}
          height={100}
          src={children.toString().substring(1, children.toString().length - 1)}
          alt=""
        />
      );
      break;
    case "boolean":
      console.log(children[1]);

      custom = children[1] === true ? "Si" : "No";
      break;
    default:
      custom = children;
      break;
  }

  return <td {...restProps}>{custom ? custom : children}</td>;
};

let searchInput = null;

export default function NewTable<T extends BaseModel>({
  originData,
  columns,
  editable = false,
  service = null,
  loading = false,
  actions = {},
  addTitle = "add",
  total = 0,
  getData = null,
  onChange = null,
  filters = null,
  components = null,
  refresh = null,
  setText = null,
  value = null,
  rowSelection = null,
  exportMethod = null,
  exportAllData = null,
  hasSelected = null,
  ChangeState = null,
  setStatusUser = null,
}: {
  originData: T[];
  editable?: boolean;
  service?: any;
  columns?: any[];
  loading?: boolean;
  actions?: any;
  addTitle?: string;
  total?: number;
  getData?: any;
  onChange?: any;
  filters?: any;
  components?: any;
  refresh?: any;
  setText?: any;
  value?: any;
  rowSelection?: any;
  exportMethod?: any;
  exportAllData?: any;
  hasSelected?: any;
  ChangeState?: any;
  setStatusUser?: any;
}) {
  const [form] = Form.useForm();
  const [data, setData] = useState(originData);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [editingKey, setEditingKey] = useState(-1);
  const [ToDelete, setToDelete] = useState<Partial<T> & { _id: React.Key }>();
  const { callEndpoint } = useFetchAndLoad();

  const isEditing = (record: T) => record._id === editingKey;

  useEffect(() => {
    setData(originData);
  }, [originData]);

  const handleAdd = () => {
    actions.add();
  };

  const handleDelete = async (key: React.Key) => {
    const result = await callEndpoint(service.remove(key));

    if (result.status === 200) {
      const newData = [...data];
      const index = newData.findIndex((item: T) => key === item._id);
      newData.splice(index, 1);
      setData(newData);
      setIsModalVisible(false);
      notification["success"]({
        message: `${addTitle.split(" ")[1]} deleted`,
        description: `${addTitle.split(" ")[1]} was successfully deleted.`,
      });
    }
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<DataType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0] as string}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        style={{ color: "rgba(0,0,0,.85)", width: 15, height: 15 }}
      />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const remove = (record: Partial<T> & { _id: React.Key }) => {
    console.log("record,", record);
    setToDelete(record);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const operation = {
    title: <span style={{ fontSize: "10px" }}>Acciones</span>,
    dataIndex: "operation",
    width: "15%",
    align: "center",
    render: (_: any, record: T) => {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {/* <Typography.Link onClick={() => view(record)}>View</Typography.Link> */}

          <Typography.Link
            target="_blank"
            rel="noopener noreferrer"
            href={`/${actions.view()}/${record._id}`}
          >
            <div className="w-10 mr-2 transform hover:text-[#1890ff] hover:scale-110">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
            </div>
          </Typography.Link>

          {/* <Typography.Link onClick={() => edit(record)}>Editar</Typography.Link> */}

          <Typography.Link
            target="_blank"
            rel="noopener noreferrer"
            href={`/${actions.edit()}/${record._id}`}
          >
            <div className="w-10 mr-2 transform hover:text-[#1890ff] hover:scale-110">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                />
              </svg>
            </div>
          </Typography.Link>

          {actions.remove !== false && (
            <Typography.Link id="deleteBtn" onClick={() => remove(record)}>
              <div className="w-10 mr-2 transform hover:text-[#1890ff] hover:scale-110">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </div>
            </Typography.Link>
          )}

          <Modal
            visible={isModalVisible}
            okButtonProps={{
              id: "delete",
              style: { backgroundColor: "#1890ff", borderColor: "#1890ff" },
            }}
            cancelButtonProps={{ style: { borderColor: "#1890ff" } }}
            title={null}
            onCancel={handleCancel}
            onOk={() => handleDelete(ToDelete._id)}
            style={{
              background: "linear-gradient(to right, #00FFFF, #00BFFF)", // Tonos de turquesa y azul
            }}
            closable={false} // Desactivar el botón de cierre (la X)
          >
            <h2 style={{ color: "blue", fontWeight: "bold" }}>
              ¿Estás seguro/a de eliminar {addTitle.split(" ")[1]}?
            </h2>
          </Modal>
        </div>
      );
    },
  };
  const mergedColumns = (
    Object.keys(actions).length > 0 ? [...columns, operation] : columns
  ).map((col) => {
    let object = {
      ...col,
      onCell: (record: T) => ({
        record,
        Type: col.type || "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
    if (col.searchable) {
      Object.assign(object, { ...getColumnSearchProps(col.dataIndex) });
    }
    return object;
  });

  return (
    <>
      <div className="flex justify-between">
        <div className="flex flex-row">
          {/* {Object.keys(actions).length > 0 && (
            <Button
              className="mr-4"
              id={addTitle.split(" ").join("-")}
              onClick={handleAdd}
              type="primary"
              style={{ marginBottom: 16, display: "flex" }}
            >
              {addTitle}
            </Button>
          )} */}

          {/* {exportMethod && (
            <Button
              className="mr-4"
              icon={<ExportOutlined />}
              id={"Exportar"}
              disabled={loading || !hasSelected}
              onClick={() => {
                if (exportMethod) {
                  exportMethod();
                }
              }}
              type="primary"
              style={{ marginBottom: 16, display: "flex" }}
            >
              Exportar
            </Button>
          )}

          {exportAllData && (
            <Button
              className="mr-4"
              icon={<ExportOutlined />}
              id={"Exportar"}
              disabled={loading || hasSelected}
              onClick={() => {
                if (exportAllData) {
                  exportAllData();
                }
              }}
              type="primary"
              style={{ marginBottom: 16, display: "flex" }}
            >
              Exportar todos
            </Button>
          )} */}

          {/* exportAllData */}
        </div>

        {setText && (
          <Input
            disabled={loading}
            placeholder={`Buscar ...`}
            value={value ? value : ""}
            onChange={(e) => setText(e["target"]?.value)}
            style={{ width: 300, height: 40, marginRight: 20 }}
          />
        )}
      </div>
      <div className="Content">
        <div className="flex  justify-between">
          {refresh && (
            <Button
              className="mr-4"
              icon={<ReloadOutlined />}
              id={"Refrescar"}
              disabled={loading}
              onClick={() => {
                if (refresh) {
                  refresh();
                }
              }}
              type="primary"
              style={{ marginBottom: 16, display: "flex" }}
            >
              Refrescar
            </Button>
          )}

          {refresh && (
            <div className="flex ">
              <Button
                className="mr-4"
                id={"Refrescar"}
                disabled={loading}
                onClick={() => {
                  if (setStatusUser) {
                    setStatusUser(true);
                  }
                }}
                type="primary"
                style={{ marginBottom: 16 }}
              >
                <Space>
                  <FaUserAlt />
                  <span>Activos</span>
                </Space>
              </Button>

              <Button
                className="mr-4"
                id={"Refrescar"}
                disabled={loading}
                onClick={() => {
                  if (setStatusUser) {
                    setStatusUser(false);
                  }
                }}
                type="primary"
                style={{ marginBottom: 16 }}
              >
                <Space>
                  <FaUserAltSlash />
                  <span>Inactivos</span>
                </Space>
              </Button>
            </div>
          )}
        </div>

        <div className="flex">
          {ChangeState && (
            <Button
              type="link"
              id={"Activar"}
              className="mr-4"
              disabled={loading || !hasSelected}
              onClick={() => {
                console.log("THIS WORKS");

                if (ChangeState) {
                  ChangeState(true);
                }
              }}
              style={{ marginBottom: 16, display: "flex" }}
            >
              Activar
            </Button>
          )}

          {ChangeState && (
            <Button
              type="link"
              id={"Desactivar"}
              disabled={loading || !hasSelected}
              onClick={() => {
                if (ChangeState) {
                  ChangeState(false);
                }
              }}
              style={{ marginBottom: 16, display: "flex" }}
            >
              Desactivar
            </Button>
          )}
        </div>

        <div className="flex justify-between mb-2">
          <div className="flex space-x-4">
            {exportMethod && (
              <Export handleClick={exportMethod} hasSelected={hasSelected} />
            )}

            {exportAllData && (
              <ExportAll
                exportAllData={exportAllData}
                hasSelected={hasSelected}
              />
            )}
          </div>
        </div>
        <Form form={form} component={false}>
          <Table
            className="NewTable"
            loading={loading}
            components={{
              body: {
                ...components?.body,
                cell: TypeCell,
              },
            }}
            rowSelection={rowSelection}
            dataSource={data}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={{
              total,
              defaultPageSize: 10,
              onChange,
            }}
          />
        </Form>
      </div>
    </>
  );
}
