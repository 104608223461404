import axios from "axios";
import { loadAbort } from "utilities";

export interface IFinalNPS {
  _id: string;
  coachee: string;
  recomendation: number;
  coachValoration: number;
  feelingsAboutCareer: string;
  feelingsAboutBonum: string;
  programFeedback: string;
  aboutCoach: string;
  createdAt: string;
  updatedAt: string;
}

const finalnps = `${process.env.REACT_APP_API_URL}finalnps`;

export const getAllDashbordFinalNps = (page: number, pageSize: number) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.get(`${finalnps}/getalldashboard`, {
        signal: controller.signal,
        params: {
          page,
          pageSize,
        },
      }),
    controller,
  };
};

export const getFinalNpsByRangeOfDates = (start: string, end: string) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.get(`${finalnps}/getByRangeOfDates`, {
        signal: controller.signal,
        params: {
          start,
          end,
        },
      }),
    controller,
  };
};
