import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import useFetchAndLoad from 'hooks/useFetchAndLoad';
import displayToast from 'utilities/toast.utility';
import { Card, Select } from 'antd';
import { Avatar, Input, Button, Form, InputNumber } from 'antd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getById, post, put } from 'services/email.service';
import { stateFromHTML } from 'draft-js-import-html';
import { EditorState, convertToRaw, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './AddEmail.scss';
import { Emails } from 'enums/Email.enum';

function AddEmail() {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const [IDS, setIDs] = useState([]);
  const [Content, setContent] = useState('');
  const [ContentState, setContentState] = useState(EditorState.createEmpty());
  const { loading, callEndpoint } = useFetchAndLoad();
  const { id } = useParams();
  const initialValues = () => ({
    Subject: '',
    Content: '',
    ID: ''
  });

  const [componentDisabled, setComponentDisabled] = useState<boolean>(false);

  const { handleSubmit, setFieldValue, handleChange, values, errors, handleBlur, touched } =
    useFormik({
      initialValues: initialValues(),
      onSubmit: async (form, { resetForm }) => {
        try {
          const item: any = document.querySelector('.rdw-text-align-wrapper')?.children?.item(0);
          item?.click();
          const data = {
            ...form,
            Content
          }
          if (window.location.pathname.includes('edit')) {
            await callEndpoint(put(id, data));
            displayToast('Correo actualizado con éxito', 'success');
          } else {
            await callEndpoint(post(data));
            displayToast('Correo creado con éxito', 'success');
          }
          navigate('/emails');
          resetForm();
        } catch (error: any) {
          if (error?.response?.data?.message) {
            displayToast(error.response.data.message, 'error');
          }
        }
      }
    });

  useEffect(() => {

    for (const key in Emails) {
      if (Object.prototype.hasOwnProperty.call(Emails, key)) {
        const element = Emails[key]
        setIDs(old => [...old, { value: element, label: key }])
      }
    }

    if (window.location.pathname.includes('view') || window.location.pathname.includes('edit')) {
      callEndpoint(getById(id)).then(({ data }) => {
        for (const key in initialValues()) {
          if (Object.prototype.hasOwnProperty.call(data.data, key)) {
            const element = data.data[key];
            form.setFieldsValue({ [key]: element });
            setFieldValue(key, element);
            if (key === 'Content') {
              setContentState(EditorState.createWithContent(stateFromHTML(element)))
              setContent(element);
            }
          }
        }
      });
    }
    if (window.location.pathname.includes('view')) {
      setComponentDisabled(true);
    }
  }, [])

  const TitleCustom = () => {
    if (window.location.pathname.includes('view')) {
      return <h2 className="title_table">Ver Correo Electronico</h2>;
    } else if (window.location.pathname.includes('edit')) {
      return <h2 className="title_table">Editar Correo Electronico</h2>;
    } else {
      return <h2 className="title_table">Crear Correo Electronico</h2>;
    }
  }

  return (
    <div className="Coaches">
      <h2 className="title_table"><TitleCustom /></h2>
      <Card>
        <Form
          className='flex form-crud'
          layout='vertical'
          form={form}
          name="AddCoach control-ref"
          onFinish={handleSubmit}
          disabled={componentDisabled}
        >

          <div className='flex pl-5 column w-100'>

            <div className='mb-5'>

              <Form.Item name="ID"
                className="mr-5 AddCoach__input"
                label="ID del correo electronico"
                rules={[{ required: true, message: 'Por favor introduce ID.' }]}
              >

                <Select
                  placeholder="Selecciona un ID"
                  onChange={(e) => {
                    setFieldValue("ID", e);
                  }}
                  value={values.ID}
                  allowClear
                  showSearch
                  options={IDS}
                ></Select>
              </Form.Item>

              <Form.Item name="Subject"
                className="mr-5 AddCoach__input"
                label="Subject del correo electronico"
                rules={[{ required: true, message: 'Por favor introduce subject.' }]}
              >
                <Input
                  name="Subject"
                  className="Add__input_control"
                  placeholder="Introduce subject"
                  onChange={handleChange}
                  value={values.Subject}
                  onBlur={handleBlur}
                />
              </Form.Item>

              <div className="container flex justify-center column">
                <Editor
                  editorState={ContentState}
                  mention={{
                    separator: ' ',
                    trigger: '{',
                    suggestions: [
                      { text: '{{{coachName}}}', value: '{{{coachName}}}' },
                      { text: '{{{coacheeName}}}', value: '{{{coacheeName}}}', }
                    ]
                  }}
                  toolbar={{
                    emoji: {
                      // icon: emoji,
                      className: undefined,
                      component: undefined,
                      popupClassName: undefined,
                      emojis: [
                        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                        '✅', '❎', '💯',
                      ],
                    },
                    colorPicker: {
                      colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                        'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                        'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                        'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                        'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                        'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                    },
                  }}
                  textAlignment='left'
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={(e) => {
                    setContentState(e);
                    setContent(draftToHtml(convertToRaw(e.getCurrentContent())));
                  }}
                />
            </div>

            </div>



            <div className='flex justify-end'>
              <Button type="primary" htmlType="submit">
                {window.location.pathname.includes('edit') ? 'Actualizar' : 'Guardar'}
              </Button>
              <Button style={{ marginLeft: 20 }} htmlType="button" onClick={() => navigate(-1)}>
                Cancelar
              </Button>
              <Button style={{ marginLeft: 20 }} type="dashed" htmlType="button" onClick={() => form.resetFields()}>
                Borrar
              </Button>
            </div>

          </div>


        </Form>
      </Card>
    </div>
  );
}

export default AddEmail;
