import { initializeApp } from 'firebase/app';
import { getAuth, browserSessionPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig =
  process.env.NODE_ENV === 'production'
    ? {
        apiKey: 'AIzaSyB5McU4c6SV_i8GQdawViwZv1tjwuSfbGQ',
        authDomain: 'bonum-web-production.firebaseapp.com',
        projectId: 'bonum-web-production',
        storageBucket: 'bonum-web-production.appspot.com',
        messagingSenderId: '257283497244',
        appId: '1:257283497244:web:68112e9d12ce94660f2ed9',
        measurementId: 'G-LTFVRX7YN3'
      }
    : {
        apiKey: 'AIzaSyBePGAmFdmabeI9jat4cFyxPg5RwML-arI',
        authDomain: 'bonum-web-test.firebaseapp.com',
        projectId: 'bonum-web-test',
        storageBucket: 'bonum-web-test.appspot.com',
        messagingSenderId: '312546331631',
        appId: '1:312546331631:web:2d20f2f62b8dcd0d91041a'
      };

export function translateFirebaseErrors(firebaseError) {
  let message = '';
  switch (firebaseError) {
    case 'auth/wrong-password': {
      message = 'La contraseña es incorrecta';
      break;
    }

    case 'auth/user-not-found': {
      message = 'El usuario no existe';
      break;
    }

    default: {
      message = 'Error, contacte al administrador';
      break;
    }
  }

  return message;
}

// Initialize Firebase
const app = initializeApp(firebaseConfig, 'service__users');
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

const setAuthPersistence = async () => {
  try {
    const persistence = await auth.setPersistence(browserSessionPersistence);
    console.log(
      '🚀 ~ file: firebase.utilities.js ~ line 46 ~ setAuthPersistence ~ persistence',
      persistence
    );
  } catch (error) {
    console.log(error);
  }
};

export { auth, firestore, storage, setAuthPersistence };
