import axios from "axios";
import { loadAbort } from "../utilities";

const route = process.env.REACT_APP_API_URL + "coach";
const usersURL = process.env.REACT_APP_API_URL;

// eslint-disable-next-line import/prefer-default-export
export const post = (data) => {
  const controller = loadAbort();
  return {
    call: () => axios.post(route, { ...data }, { signal: controller.signal }),
    controller,
  };
};

export const getUser = (id) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.get(`${usersURL}/userRol/${id}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const CoacheeByCoacheeState = (id: string, state: string) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/ByCoacheeState/${state}/${id}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const getAll = (page: number = 1, pageSize: number = 10) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/GetAllDashboard?page=${page}&pageSize=${pageSize}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const googleAuth = (page: number = 1, pageSize: number = 10) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/GoogleAuth`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const GetAllCoachs = () => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/GetAllCoachs`, { signal: controller.signal }),
    controller,
  };
};

export const getAllWithFilters = (
  page: number = 1,
  pageSize: number = 10,
  text: any
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.patch(
        `${route}/GetAllDashboardWithFilters?page=${page}&pageSize=${pageSize}&searchQuery=${text}`,
        null,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getAllWithFilters2 = (
  page: number = 1,
  pageSize: number = 10,
  text: any
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.patch(
        `${route}/GetAllDashboardWithFilters?page=${page}&pageSize=${pageSize}&searchQuery=${text}`,
        null,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const CoacheeBySessionDate = (id: string, since: string) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/BySessionDate/${since}/${id}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const getById = (id: string) => {
  const controller = loadAbort();
  return {
    call: () => axios.get(`${route}/${id}`, { signal: controller.signal }),
    controller,
  };
};

export const getCoachById = (id: string) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${process.env.REACT_APP_API_URL + "coach"}/${id}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const getByCoachId = (id: string) => {
  const controller = loadAbort();
  return {
    call: () => axios.get(`${route}/${id}`, { signal: controller.signal }),
    controller,
  };
};

export const remove = (id) => {
  const controller = loadAbort();
  return {
    call: () => axios.delete(`${route}/${id}`, { signal: controller.signal }),
    controller,
  };
};

export const put = (id, data) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.put(`${route}/${id}`, { ...data }, { signal: controller.signal }),
    controller,
  };
};

export const refreshToken = ({ code }) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.put(
        `${route}/RefreshToken`,
        { code },
        { signal: controller.signal }
      ),
    controller,
  };
};

export const updateCoachee = (id, data) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.put(
        `${route}/UpdateCoachee/${id}`,
        { ...data },
        { signal: controller.signal }
      ),
    controller,
  };
};

export const removeCoachee = (id, data) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.put(
        `${route}/RemoveCoachee/${id}`,
        { ...data },
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getActiveCoaches = (page: number = 1, pageSize: number = 10) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/GetActiveCoaches?page=${page}&pageSize=${pageSize}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const getUnActiveCoaches = (page: number = 1, pageSize: number = 10) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(
        `${route}/GetUnActiveCoaches?page=${page}&pageSize=${pageSize}`,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getAlphabeticalCoaches = (
  page: number = 1,
  pageSize: number = 10,
  alphabeticalOrder
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(
        `${route}/getAlphabeticalCoaches?page=${page}&pageSize=${pageSize}&alphabeticalOrder=${alphabeticalOrder}`,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getSearchWithFilters = (
  page: number = 1,
  pageSize: number = 10,
  filters: any
) => {
  const controller = loadAbort();

  const params = new URLSearchParams({
    page: page.toString(),
    pageSize: pageSize.toString(),
  });

  if (filters?.statusUser !== undefined) {
    params.append("statusUser", filters.statusUser.toString());
  }

  if (filters?.languages && filters.languages.length > 0) {
    params.append("languages", filters.languages.join(","));
  }

  if (filters?.alphabeticalOrder !== undefined) {
    params.append("alphabeticalOrder", filters?.alphabeticalOrder?.toString());
  }

  const queryString = params.toString();

  return {
    call: () =>
      axios.get(`${route}/getSearchWithFilters?${queryString}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export default {
  post,
  remove,
  put,
};
