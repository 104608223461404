export enum Emails {
    CHECK_IN = "CHECK_IN",
    INVITE_COACHEE = 'invite.coachee',
    INVITE_COACH = 'invite.coach',
    ADMIN_INVITE = 'adminInvite',
    AUTO_EVALUATION = 'auto.evaluation',
    EVALUATION360 = "EVALUATION360",
    REMEMBERCHOOSECOACH = "REMEMBERCHOOSECOACH",
    WELCOME_COMPANY = 'WELCOME_COMPANY',
    INVITE_USER = 'invite',
    EVALUATION = 'evaluation',
    RESULTS_EVALUATION360 = "RESULTS_EVALUATION360",
    SESSION_SCHEDULED = "session-scheduled",
    SESSION_SCHEDULED_COACH = "SESSION_SCHEDULED_COACH",
    SESSION_SCHEDULE_ALERT_COACHEE = "SESSION_SCHEDULE_ALERT_COACHEE",
    SESSION_CANCELED = "SESSION_CANCELED",
    SESSION_CANCELED_COACH = "SESSION_CANCELED_COACH",
    FILL_ASSIGNMENTS = "FILL_ASSIGNMENTS",
    MEETING_IN_ALTERNAL_CALL = "MEETING_IN_ALTERNAL_CALL",
    FEEDBACK_SESSION_COACHEE = "FEEDBACK_SESSION_COACHEE",
    MEETING_IN_FIVE_MINUTES = "MEETING_IN_FIVE_MINUTES",
    MEETING_IN_ONE_HOUR = "MEETING_IN_ONE_HOUR",
    MEETING_IN_ONE_HOUR_COACH = "MEETING_IN_ONE_HOUR_COACH",
    MEETING_SOON = "MEETING_SOON",
    OUTSTANDING_ASSIGNMENTS = "OUTSTANDING_ASSIGNMENTS",
    END_MEETING_COACHEE = "END_MEETING_COACHEE",
    END_MEETING_COACH = "END_MEETING_COACH",
    END_SESSION = "END_SESSION",
    END_SESSION_COACH = "END_SESSION_COACH",
    MIDDLE_PROCESS = "MIDDLE_PROCESS",
    FINAL_EVALUATION = "FINAL_EVALUATION",
    REMEMBER_SCHEDULE_SESSION = "REMEMBER_SCHEDULE_SESSION",
    PROGRAM_COMPLETED = "PROGRAM_COMPLETED",
    REINVITE = "REINVITE",
    RESCHEDULE_SESSION = "RESCHEDULE_SESSION",
    REINVITE_EVALUATION360_FINAL = "REINVITE_EVALUATION360_FINAL",
    MIDDLE_PROCESS_COACH = "MIDDLE_PROCESS_COACH",
    COMPANY_FINAL = "COMPANY_FINAL",
    EVALUATOR_REINVITE = "EVALUATOR_REINVITE"
}