import React from "react";

const CoachButton = ({ setCoachModal }) => {
  return (
    <div className="mb-6" onClick={() => setCoachModal(true)}>
      <label tabIndex={0} className="cursor-pointer">
        <div className="px-5 w-36 py-2.5 relative rounded-2xl group text-white font-medium inline-block h-12 text-center">
          <span className="absolute top-0 left-0 w-full h-full rounded-2xl filter bg-[#1890ff]"></span>
          <span className="relative flex items-center justify-center h-full w-full text-xl rounded-2xl">
            Coach
          </span>
        </div>
      </label>
    </div>
  );
};

export default CoachButton;
