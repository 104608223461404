import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import AddEmail from "./components/AddEmail";
import BaseTable from "../../components/base/base-table";
import adminService, { getAll } from "../../services/email.service";
import useFetchAndLoad from "../../hooks/useFetchAndLoad";
import { useNavigate } from "react-router-dom";
import { Img } from "@chakra-ui/react";
import LogoBonum from "assets/images/logo.png";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import "./Email.scss";
function Coachees() {
  const [modal, setModal] = useState(false);
  const onClose = () => setModal(false);

  const router = useNavigate();

  const { loading, callEndpoint } = useFetchAndLoad();

  const [data, setData] = useState([]);

  const getData = async () => {
    const { data } = await callEndpoint(getAll());
    setData(data.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "ID",
      width: "15%",
      editable: true,
      searchable: true,
    },
    {
      title: "Subject",
      dataIndex: "Subject",
      width: "15%",
      ellipsis: true,
      searchable: true,
      editable: true,
    },
    {
      title: "Content",
      dataIndex: "Content",
      ellipsis: true,
      width: "20%",
      editable: true,
    },
  ];

  return (
    <div className="admin">
      <Modal
        isOpen={modal}
        onClose={onClose}
        content={<AddEmail />}
        title="Añadir Email"
      />
      <h2 className="flex justify-center">Correos Electronicos</h2>
      <div className="admin__content Content">
        <BaseTable
          loading={loading}
          originData={data}
          columns={columns}
          actions={{
            view: () => "emails/view",
            add: () => router("/emails/add"),
            edit: () => "emails/edit",
          }}
          addTitle={"Add Email"}
          service={adminService}
        />
      </div>
    </div>
  );
}

export default Coachees;
