import { useLocation } from "react-router-dom";
import useFetchAndLoad from "hooks/useFetchAndLoad";
import { useState, useEffect } from "react";
import { refreshToken } from "services/coach.service";

const RefreshToken = () => {
  const { loading, callEndpoint } = useFetchAndLoad();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const code = queryParams.get("code");

  const [tokenRefresh, setTokenRefreshed] = useState(false);

  const handleRefreshToken = async () => {
    const { data } = await callEndpoint(refreshToken({ code }));

    setTokenRefreshed(true);
  };

  useEffect(() => {
    handleRefreshToken();
  }, [code]);

  return (
    <>
      <div className="flex justify-center items-center">
        <h1>RefreshToken</h1>

        <br />
      </div>
      <div className="flex justify-center items-center ">
        {tokenRefresh ? (
          <h3 className="text-green-500">Token Actualizado</h3>
        ) : null}
      </div>
    </>
  );
};

export default RefreshToken;
